import React, { createContext, useState, useEffect, useContext } from 'react';

const API_URL = process.env.REACT_APP_API_URL;
const POLLING_INTERVAL = 10 * 1000; // 10 seconds

const TokenInfoContext = createContext(null);

const TokenInfoProvider = ({ children }) => {
  const [tokenInfo, setTokenInfo] = useState({
    price: null,
    supply: null,
    market_cap: null,
    isLoading: true,
    error: null
  });

  const fetchTokenInfo = async (forceRefresh = false) => {
    try {
      const queryParam = forceRefresh ? '?force_refresh=true' : '';
      const response = await fetch(`${API_URL}/token${queryParam}`);
      const data = await response.json();

      setTokenInfo({
        price: data.price,
        supply: data.supply,
        market_cap: data.market_cap,
        isLoading: false,
        error: null
      });

      console.log(
        forceRefresh ? 'Uncached fetch (force refresh)' : 'Cached fetch',
        {
          cacheStatus: response.headers.get('cf-cache-status'),
          age: response.headers.get('age'),
        }
      );
    } catch (error) {
      console.error('Error fetching token info:', error);
      setTokenInfo(prev => ({
        ...prev,
        isLoading: false,
        error: error.message
      }));
    }
  };

  useEffect(() => {
    // Initial fetch to use CDN cache
    fetchTokenInfo();
    // Subsequent fetch to the server
    fetchTokenInfo(true)

    // Polling for fresh data directly from the server
    const pollInterval = setInterval(() => fetchTokenInfo(true), POLLING_INTERVAL);

    return () => clearInterval(pollInterval);
  }, []);

  return (
    <TokenInfoContext.Provider value={tokenInfo}>
      {children}
    </TokenInfoContext.Provider>
  );
};

const PriceBlock = () => {
  const { price, error } = useContext(TokenInfoContext);

  return (
    <div className="column is-one-third">
      <div className="card">
        <div className="card-content">
          <div className="content">
            <h3 className="title is-4">Price</h3>
            <p>
              {error
                ? 'Error loading'
                : `$${(price || 0).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SupplyBlock = () => {
  const { supply, error } = useContext(TokenInfoContext);

  return (
    <div className="column is-one-third">
      <div className="card">
        <div className="card-content">
          <div className="content">
            <h3 className="title is-4">Supply</h3>
            <p>{error ? 'Error loading' : `${(supply || 0).toLocaleString()}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const MarketCapBlock = () => {
  const { market_cap, error } = useContext(TokenInfoContext);

  return (
    <div className="column is-one-third">
      <div className="card">
        <div className="card-content">
          <div className="content">
            <h3 className="title is-4">Market Cap</h3>
            <p>
              {error
                ? 'Error loading'
                : `$${(market_cap || 0).toLocaleString()}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const TokenInfoBlock = () => {
  return (
    <div className="columns is-flex-wrap-wrap is-flex">
      <PriceBlock />
      <SupplyBlock />
      <MarketCapBlock />
    </div>
  );
};

export { TokenInfoProvider, TokenInfoBlock };
