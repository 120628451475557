import React from 'react';

const HolderSummary = () => {
    return (
        <div class="section is-full has-text-centered address-summary">
            <h2>Summary for holders</h2>
            <div class="section is-full">
                <h3>Holder Table</h3>
            </div>
        </div>
    );
}

export default HolderSummary;