import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = ({ toggleTheme, theme }) => {

  const location = useLocation();

  const toggleBurgerMenu = () => {
    const menu = document.getElementById('navbarMenu');
    menu.classList.toggle('is-active');
  };

  const isActive = (path) => {
    return location.pathname === path ? 'is-active' : '';
  };

  return (
    <nav className="navbar is-primary is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className={`navbar-item ${isActive('/')}`}>
          <strong>EEFI Tools</strong>
        </Link>
        <a
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarMenu"
          onClick={toggleBurgerMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarMenu" className="navbar-menu">
        <div className="navbar-start">
          <Link to="/treasury" className={`navbar-item ${isActive('/treasury')}`}>Treasury</Link>
          <Link to="/charts" className={`navbar-item ${isActive('/charts')}`}>Charts</Link>
          <Link to="/burns" className={`navbar-item ${isActive('/burns')}`}>Burns</Link>
          <Link to="/mints" className={`navbar-item ${isActive('/mints')}`}>Mints</Link>
          <Link to="/holders" className={`navbar-item ${isActive('/holders')}`}>Holders</Link>
        </div>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">
        <button
              className="button is-light"
              onClick={toggleTheme}
              aria-label="Toggle Theme"
              title="Toggle Theme"
            >
              {theme === "light" ? "☀️" : "🌙"}
            </button>
        </div>
      </div>

    </nav>
  );
};

export default Navbar;
