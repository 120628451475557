import React, { useState, useEffect, useCallback } from "react";

const API_URL = process.env.REACT_APP_API_URL;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache duration

const BurnsTable = () => {
  const [burns, setBurns] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      console.log('Fetching burns from API...');
      const response = await fetch(`${API_URL}/burn`);
      const jsonData = await response.json();
      
      // Enhanced caching with error handling
      localStorage.setItem('burns', JSON.stringify({
        data: jsonData,
        timestamp: Date.now()
      }));
      
      setBurns(jsonData);
      setError(null);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message || "An error occurred");
      
      // Optionally, still use stale cache data if available
      const cachedBurns = localStorage.getItem('burns');
      if (cachedBurns) {
        try {
          const { data } = JSON.parse(cachedBurns);
          setBurns(data);
          console.log('Using cached burns after error fetching');
        } catch {}
      }
    } finally {
      setLoading(false);
    }
  }, []);


  // Fetch data from API
  useEffect(() => {
    // Initial data load with cache check
    const loadData = async () => {
      const cachedBurns = localStorage.getItem('burns');
      try {
        if (cachedBurns) {
          const { data, timestamp } = JSON.parse(cachedBurns);
          if (Date.now() - timestamp <= CACHE_DURATION) {
            setBurns(data);
            setLoading(false);
            console.log('Used cached burns');
          } else {
            await fetchData();
          }
        } else {
          await fetchData();
        }
      } catch (parseError) {
        console.error('Cache parsing error:', parseError);
        await fetchData();
      }
    };

    loadData();

    // Optimized polling
    const intervalId = setInterval(() => {
      const cachedBurns = localStorage.getItem('burns');
      const { timestamp } = JSON.parse(cachedBurns) || {};
      
      if (!timestamp || Date.now() - timestamp > CACHE_DURATION) {
        fetchData();
      }
    }, 10000);
    
    return () => clearInterval(intervalId);
  }, [fetchData]);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Pagination logic
  //const totalItems = data.length;
  //const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  //const currentData = data.slice(startIndex, startIndex + itemsPerPage);

  const totalItems = Array.isArray(burns) ? burns.length : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentData = Array.isArray(burns)
    ? burns.slice(startIndex, startIndex + itemsPerPage)
    : [];

  // Build the pagination links
  const getPaginationPages = () => {
    const pages = [];
    
    // Always show first page
    pages.push(1);

    // Show ellipsis if there's a gap between first and current-1
    if (currentPage > 3) {
      pages.push("ellipsis-start"); // render "..." for this
    }

    // Show currentPage-1 if greater than 1 and not too close to start
    if (currentPage > 2) {
      pages.push(currentPage - 1);
    }

    // Show currentPage
    if (currentPage !== 1 && currentPage !== totalPages) {
      pages.push(currentPage);
    }

    // Show currentPage+1 if less than totalPages and not too close to end
    if (currentPage < totalPages - 1) {
      pages.push(currentPage + 1);
    }

    // Show ellipsis if there's a gap between current+1 and last page
    if (currentPage < totalPages - 2) {
      pages.push("ellipsis-end"); // render "..." for this
    }

    // Always show last page if not the same as first
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    // Filter out duplicates (in case currentPage is 1 or totalPages)
    return [...new Set(pages)];
  };

  const pageNumbers = getPaginationPages();

  const handlePageClick = (page) => {
    if (typeof page === "number") {
      setCurrentPage(page);
    }
  };

  return (
    <div className="container">
      <h2 className="title is-4">Burns</h2>
      <table className="table is-striped is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Burnt</th>
            <th>Source</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {(currentData || []).map((item, index) => (
            <tr key={index}>
              <td>{item.value}</td>
              <td>{item.type}</td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {totalPages > 1 && (
        <nav className="pagination is-right" role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            {pageNumbers.map((page, index) => {
              if (page === "ellipsis-start" || page === "ellipsis-end") {
                return (
                  <li key={index}>
                    <span className="pagination-ellipsis">&hellip;</span>
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <button
                      className={`pagination-link ${page === currentPage ? "is-current" : ""}`}
                      onClick={() => handlePageClick(page)}
                      aria-label={`Goto page ${page}`}
                    >
                      {page}
                    </button>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default BurnsTable;
