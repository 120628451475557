import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const BurnAreaChart = ({ data }) => (
  <div>
    <h2>Cumulative Totals (Stacked Area Chart)</h2>
    <AreaChart
      width={800}
      height={400}
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="timestamp"
        type="number"
        domain={['dataMin', 'dataMax']}
        scale="time"
        tickFormatter={(timestamp) => {
          const date = new Date(timestamp);
          return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        }}
      />
      <YAxis />
      <Tooltip
        labelFormatter={label => {
          const date = new Date(label);
          return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
          }
        }
        formatter={(value) => value.toFixed(2)} 
      />
      <Legend />
      {/* stackId='1' makes these areas stack on top of each other */}
      <Area
        type="monotone"
        dataKey="furnaceCumulative"
        name="Furnace Total"
        stroke="#FF0000"
        fill="#FF0000"
        fillOpacity={0.3}
        stackId={1}
      />
      <Area
        type="monotone"
        dataKey="vaultCumulative"
        name="Vault Total"
        stroke="#FFA500"
        fill="#FFA500"
        fillOpacity={0.3}
        stackId={1}
      />
      <Area
        type="monotone"
        dataKey="totalCumulative"
        name="Total"
        stroke="transparent"
        fill="transparent"
        fillOpacity={0.3}
      />
    </AreaChart>
  </div>
);

export default BurnAreaChart;
