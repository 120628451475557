import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const BurnLineChart = ({ data }) => (
  <div>
    <h2>Daily Sums by Source (Line Chart)</h2>
    <LineChart
      width={800}
      height={400}
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="timestamp"
        type="number"
        domain={['dataMin', 'dataMax']}
        scale="time"
        tickFormatter={timestamp => {
          const date = new Date(timestamp);
          return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        }}
      />
      <YAxis />
      <Tooltip
        labelFormatter={label => {
          const date = new Date(label);
          return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        }}
        formatter={(value) => value.toFixed(2)} 
      />
      <Legend />
      <Line type="monotone" dataKey="furnaceValue" name="Furnace" stroke="#ff7300" dot={false} />
      <Line type="monotone" dataKey="vaultValue" name="Vault" stroke="#0084ff" dot={false} />
    </LineChart>
  </div>
);

export default BurnLineChart;
