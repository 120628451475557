import React, { useEffect, useState } from 'react';

//const API_URL = process.env.REACT_APP_API_URL;
//const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache duration

const UniswapPositions = ({ liquidity }) => {
  /*
  const [positions, setPositions] = useState(() => {
    // Initialize with cached data if available
    const cached = localStorage.getItem('liquidity');
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      const isExpired = Date.now() - timestamp > CACHE_DURATION;
      
      if (!isExpired) {
        return data;
      }
    }
    return [];
  });

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await fetch(`${API_URL}/liquidity`);
        const data = await response.json();
        
        // Save to cache
        localStorage.setItem('liquidity', JSON.stringify({
          data,
          timestamp: Date.now()
        }));
        
        setPositions(data);
      } catch (error) {
        console.error('Error fetching Uniswap V3 positions:', error);
      }
    };

    fetchPositions(); // Initial fetch
    
    // Set up polling every 10 seconds
    const intervalId = setInterval(fetchPositions, 10000);
    
    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [address]);
  */

  return (
    <table className="table is-bordered is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Pool</th>
          <th>Liquidity</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {liquidity.map((position) => (
          <tr key={position.pool}>
            <td>{position.pool}</td>
            <td>
              {Object.entries(position.liquidity).map(([token, amount]) => (
                <div key={token}>
                  {token}: {amount.toLocaleString(undefined, { 
                    minimumFractionDigits: 2, 
                    maximumFractionDigits: 2 
                  })}
                </div>
              ))}
            </td>
            <td>
              ${position.value.toLocaleString(undefined, { 
                minimumFractionDigits: 2, 
                maximumFractionDigits: 2 
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UniswapPositions;