import React, { useEffect, useState } from 'react';
import BurnAreaChart from './BurnAreaChart';
import BurnLineChart from './BurnLineChart';

const API_URL = process.env.REACT_APP_API_URL;

const BurnCharts = () => {
  const [lineData, setLineData] = useState([]);
  const [areaData, setAreaData] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/burn`)
      .then(response => response.json())
      .then(rawData => {
        // Transform and sort by timestamp
        const chartData = rawData.map(item => ({
          timestamp: new Date(item.timestamp).getTime(),
          value: item.value,
          source: item.type // "FURNACE" or "VAULT"
        }));
        chartData.sort((a, b) => a.timestamp - b.timestamp);

        // Group by day and source
        const dailySumsBySource = {};
        chartData.forEach(d => {
          const date = new Date(d.timestamp);
          const dateKey = date.toLocaleDateString('en-CA'); // YYYY-MM-DD
            
          if (!dailySumsBySource[dateKey]) {
            dailySumsBySource[dateKey] = { furnace: 0, vault: 0 };
          }
          
          if (d.source === 'FURNACE') {
            dailySumsBySource[dateKey].furnace += d.value;
          } else if (d.source === 'VAULT') {
            dailySumsBySource[dateKey].vault += d.value;
          }
        });

        // Convert daily sums to an array and sort it by date
        const dailyArray = Object.keys(dailySumsBySource).map(dateStr => {
          const [year, month, day] = dateStr.split('-');
          const dateObj = new Date(year, month - 1, day);
          return {
            timestamp: dateObj.getTime(),
            furnaceValue: dailySumsBySource[dateStr].furnace,
            vaultValue: dailySumsBySource[dateStr].vault,
            totalValue: (dailySumsBySource[dateStr].furnace + dailySumsBySource[dateStr].vault)
          };
        });
        dailyArray.sort((a, b) => a.timestamp - b.timestamp);

        // lineData (daily sums as-is)
        const lineDataResult = dailyArray;

        // areaData (cumulative sums)
        let furnaceCumulative = 0;
        let vaultCumulative = 0;
        let totalCumulative = 0;
        const areaDataResult = dailyArray.map(d => {
          furnaceCumulative += d.furnaceValue;
          vaultCumulative += d.vaultValue;
          totalCumulative = furnaceCumulative + vaultCumulative;
          return {
            timestamp: d.timestamp,
            furnaceCumulative,
            vaultCumulative,
            totalCumulative
          };
        });

        setLineData(lineDataResult);
        setAreaData(areaDataResult);
      })
      .catch(err => console.error("Error fetching data: ", err));
  }, []);

  return (
    <div>
      <h1>Burn Charts</h1>
      {/* Stacked Area Chart: cumulative totals, stacked by source */}
      <BurnAreaChart data={areaData} />
      {/* Line Chart: separate lines for Furnace and Vault daily sums */}
      <BurnLineChart data={lineData} />      
    </div>
  );
};

export default BurnCharts;
