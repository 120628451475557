import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import './styles/app.scss';

import Navbar from './components/Navbar'; // Import Navbar
import { TokenInfoProvider, TokenInfoBlock } from "./components/TokenBlock/TokenBlock";
import AddressSummary from './components/AddressSummary/AddressSummary';
import BurnsTable from "./components/Burns/BurnTable";
import MintsTable from "./components/Mints/MintsTable";
import HolderSummary from "./components/Holders/Holders";
import BurnCharts from "./components/Burns/BurnCharts";

const Home = () => (
  <div className="column is-full is-centered">
    <div className="content has-text-centered">
      <h2>Welcome to eefi.tools</h2>
      <p>A community driven dashboard extravaganza!</p>
    </div>
    <div className="content has-text-centered">
      <TokenInfoProvider>
        <TokenInfoBlock />
      </TokenInfoProvider>  
    </div>
  </div>
  
);

const App = () => {
  useEffect(() => {
    document.title = 'EEFI Tools';
  }, []);

  const [theme, setTheme] = useState('light');
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <>
    <Helmet defaultTitle="EEFI Tools">
        <meta name="description" content="EEFI Tools - Comprehensive EEFI Analytics" />
    </Helmet>
    <Router>
    <Navbar toggleTheme={toggleTheme} theme={theme} />
    <div className="hero is-fullheight">
          <div className="hero-body">
            <div className="container is-fluid">
              <section className="section">
                <div label="route-div" className="columns is-multiline is-centered">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/treasury" element={<AddressSummary address="0xf950a86013baa227009771181a885e369e158da3" />} />
                  <Route path="/charts" element={<BurnCharts />} />
                  <Route path="/burns" element={<BurnsTable />} />
                  <Route path="/mints" element={<MintsTable />} />
                  <Route path="/holders" element={<HolderSummary />} />
                </Routes>
                </div>
              </section>
            </div>
          </div>
        </div>
    </Router>
    </>
  );
};

export default App;
