import React, { useEffect, useState } from 'react';

const API_URL = process.env.REACT_APP_API_URL;
const CACHE_KEY = 'treasuryTokens';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache duration

const TokenTable = ({ tokens }) => {
  /*
    const [tokens, setTokens] = useState(() => {
    // Initialize with cached data if available
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      const isExpired = Date.now() - timestamp > CACHE_DURATION;
      
      if (!isExpired) {
        return data;
      }
    }
    return [];
  });

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await fetch(`${API_URL}/treasury`);
        const data = await response.json();
        
        // Save to cache
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          data,
          timestamp: Date.now()
        }));
        
        setTokens(data);
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    };

    fetchTokens(); // Initial fetch
    
    // Set up polling every 10 seconds
    const intervalId = setInterval(fetchTokens, 10000);
    
    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [address]);
*/

const formatTwoDecimal = (number) =>
    number.toLocaleString(undefined, { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    });

  const formatZeroDecimal = (number) =>
    number.toLocaleString(undefined, { 
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0 
    });

  return (
    <table className="table is-bordered is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th className="has-text-center">Symbol</th>
          <th className="has-text-center">Price</th>
          <th className="has-text-center">Amount</th>
          <th className="has-text-center">Value</th>
        </tr>
      </thead>
      <tbody>
        {tokens.map((token) => (
          <tr key={token.symbol}>
            <td className="has-text-center">{token.symbol}</td>
            <td className="has-text-right">
              ${formatTwoDecimal(token.price)}
            </td>
            <td className="has-text-right">
              {formatTwoDecimal(token.amount)}
            </td>
            <td className="has-text-right">
              ${formatZeroDecimal(token.value)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TokenTable;