import React, { useState, useEffect } from 'react';
import TokenTable from './TokenTable';
import UniswapPositions from './UniswapPositions';

const API_URL = process.env.REACT_APP_API_URL;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache duration

const AddressSummary = ({ address }) => {
    const [tokens, setTokens] = useState([]);
    const [liquidity, setLiquidity] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            console.log(`Fetching tokens/liquidity for ${address} from ${API_URL}`);
            // Fetch tokens
            const tokensResponse = await fetch(`${API_URL}/treasury`);
            const tokensData = await tokensResponse.json();
            
            // Fetch Uniswap positions
            const liquidityResponse = await fetch(`${API_URL}/liquidity`);
            const liquidityData = await liquidityResponse.json();

            // Update state
            setTokens(tokensData);
            setLiquidity(liquidityData);
            
            // Cache data
            localStorage.setItem('treasury', JSON.stringify({
                data: tokensData,
                timestamp: Date.now()
            }));
            localStorage.setItem('liquidity', JSON.stringify({
                data: liquidityData,
                timestamp: Date.now()
            }));

            setIsLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Check cache first for tokens
        const cachedTokens = localStorage.getItem('treasury');
        if (cachedTokens) {
            const { data, timestamp } = JSON.parse(cachedTokens);
            if (Date.now() - timestamp <= CACHE_DURATION) {
                setTokens(data);
                console.log('Using cached tokens');
            }
        }
        const cachedLiquidity = localStorage.getItem('liquidity');
        if (cachedLiquidity) {
            const { data, timestamp } = JSON.parse(cachedLiquidity);
            if (Date.now() - timestamp <= CACHE_DURATION) {
                setLiquidity(data);
                console.log('Using cached liquidity');
            }
        }

        // Fetch initial data
        fetchData();
        
        // Set up polling
        const intervalId = setInterval(fetchData, 10000);
        
        // Cleanup
        return () => clearInterval(intervalId);
    }, [address]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading data</div>;

    return (
        <div className="section is-full has-text-centered address-summary">
            <h2>Treasury: {address}</h2>
            <div className="section is-full">
                <h3>Tokens</h3>
                <TokenTable tokens={tokens} />
            </div>
            <div className="section">
                <h3>Uniswap Positions</h3>
                <UniswapPositions liquidity={liquidity} />
            </div>
        </div>
    );
};

export default AddressSummary;